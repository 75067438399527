@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
.PopupAbout {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(black, 0.8);
	z-index: 10;
	@include flex('center', 'center');
	.PopupAbout-closeLayer {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.PopupAbout-content {
		position: relative;
		width: 51em;
		// height: 30em;
		overflow: auto;
		background-color: white;
		border-radius: 0.4em;
		color: black;
		text-align: left;

		.PopupAbout-header {
			position: relative;
			padding: 1em 2em 0 2em;
			.PopupAbout-title {
				position: relative;
				font-weight: bold;
				margin-bottom: 1em;
				span {
					font-size: 2em;
				}
			}
			.PopupAbout-closeBtn {
				position: absolute;
				top: 0em;
				right: 0em;
				width: 2.5em;
				height: 2.5em;
				background-image: url('../../assets/images/icon-close.svg');
				background-size: auto 1em;
				background-position: center center;
				&:hover {
					background-size: auto 1.2em;
				}
			}
		}
		.PopupAbout-body {
			position: relative;
			padding: 0em 2em 1em 2em;
			.PopupAbout-text {
				margin-bottom: 2em;
				p:first-of-type {margin-top: 0;}
				p:last-of-type {margin-bottom: 0;}

				a {
					text-decoration: underline;
					&.facebook {
						padding-left: 1.3em;
						background-image: url('../../assets/images/facebook.svg');
						background-size: auto 100%;
						background-position: left center;
					}
				}
			}
		}
	}
	
	&.portrait {
		padding-top: 2em;
		@include flex('center', 'flex-start');
		.PopupAbout-content {
			width: calc(100% - 4em);
			height: auto;
			max-height: calc(100% - 10em);
			.PopupAbout-body { 
				padding: 0em 1.3em 1em 1.3em;
			}
		}
	}
}
