@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
.Venues {
	position: relative;
	width: 100%;
	min-height: 100%;
	overflow: auto;
	color: white;
	text-align: left;
	padding: 1em 1em 0 1em;

	.Venues-venue {
		position: relative;
		display: inline-block;
		width: 15em;
		height: 13em;
		margin: 0 1em 1em 0;
		padding-top: 10em;
		color: white;
		background-color: black;
		line-height: 1em;
		text-align: left;
		border-radius: 0.4em;
		background-color: black;
		background-size: cover;		
		background-position: top center;
		background-repeat: no-repeat;
		overflow: hidden;
		cursor: pointer;
		&:nth-child(4n) {
			margin-right: 0;
		}

		.Venues-venueStatus {
			position: absolute;
			top: 1em;
			right: 1em;
			padding: 0.25em 0.5em;
			background-color: white;
			span {
				font-size: 1.5em;
				color: red;
				font-weight: bold;
			}
		}

		.Venues-venueInfo {
			position: absolute;
			top: 10em;
			left: 0;
			width: 100%;
			height: 13em;
			padding: 0.5em;
			background-color: rgba(0, 0, 0, 0.8);
			transition: top 0.5s ease-in-out;
			-webkit-transition: top 0.25s ease-in-out;
			overflow: hidden;
			cursor: pointer;

			
			.Venues-venueName {
				font-weight: bold;				
				padding-bottom: 0.25em;
				white-space: nowrap;	
			}
			.Venues-numberOfRooms {
				color: gray;
				font-size: 0.85em;
				line-height: 1em;
				white-space: nowrap;		
			}
		}
	}	

	&.portrait {
		.Venues-venue { 
			margin: 0 1em 1em 0;
			&:nth-child(2n) {
				margin-right: 0;
			}
		}
	}
}