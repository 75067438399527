@import '../styles/variables';
@import '../styles/fonts';
@import '../styles/mixins';
.App {
	position: relative;
	width: 100%;
	height: 100%;
	min-height: 100%;
	color: black;
	background-color: #1F2833;
	text-align: center;
	overflow-y: auto;
	overflow-x: hidden;
}