@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
.Filters {
	position: relative;
	padding: 2em 0 ;
	text-align: left;
	z-index: 1;
	.Filters-location {
		position: relative;
		margin-bottom: 0.5em;
		z-index: 2;
	}
	.Filters-language {
		position: relative;
		margin-bottom: 0.5em;
		z-index: 1;
	}
	.Filters-status {
		position: relative;
		margin-bottom: 0.5em;
		z-index: 1;
	
	}
	.Filters-sorting {
		position: relative;
		z-index: 1;
	}	
}