@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
.StatusPanel {
	position: relative;
	.StatusPanel-title {
		color: white;
		width: 11em;
		display: inline-block;
		vertical-align: middle;
		font-weight: bold;
	}

	.StatusPanel-future,
	.StatusPanel-closed,
	.StatusPanel-completed {
		.StatusPanel-container {
			display: inline-block;
			vertical-align: middle;
			.StatusPanel-toggleBtn {
				width: 1.25em;
				height: 1.25em;
				border: 0.1em solid white;
				cursor: pointer;
				border-radius: 0.4em;
				&.selected {
					background-image: url('../../../assets/images/icon-check-mark.svg');
					background-size: auto 90%;
				}
			}
			
		}	
	}
	.StatusPanel-closed,
	.StatusPanel-completed {
		margin-top: 0.5em;
	}
}