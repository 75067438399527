@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
.LanguagesPanel {
	position: relative;
	.LanguagesPanel-title {
		color: white;
		width: 11em;
		display: inline-block;
		vertical-align: middle;
		font-weight: bold;
	}

	.LanguagesPanel-container {
		display: inline-block;
		vertical-align: middle;
		.LanguagesPanel-icon {
			display: inline-block;
			position: relative;
			vertical-align: middle;
			width: 1.25em;
			height: 1.25em;
			margin-right: 0.5em;
			background-position: center center;
			background-size: contain;
			background-repeat: no-repeat;
			opacity: 0.5;
			filter: grayscale(1);
			-webkit-filter: grayscale(1);
			cursor: pointer;
			&.da {background-image: url('../../../assets/images/icon-da.svg');}
			&.en {background-image: url('../../../assets/images/icon-en.svg');}
			&.de {background-image: url('../../../assets/images/icon-de.svg');}
			&.selected {
				opacity: 1;
				filter: grayscale(0);
				-webkit-filter: grayscale(0);
			}
		}
	}
}



