@import 'styles/variables';
@import 'styles/fonts';
@import 'styles/mixins';
html {
	position: relative;
	height: 100%;
}

body {
	position: relative;
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
	font-size: calc(18 * ((100vw / 1280)));
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: normal;
	color: white;
	background-color: #1F2833;
	@include touchaction(manipulation);
	@include fontsmoothing();
	@include taphighlightcolor(transparent);
}

#root {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

*,
*::after,
*::before {
	box-sizing: border-box;
  font-family: 'Source Sans Pro', sans-serif;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

button {
	background-image: none;
	@include taphighlightcolor('transparent');
	@include no-select();
	&:focus {
		outline: 0;
	}
	&::-moz-focus-inner {
		border: 0;
	}
}

ul, ol {
	padding-inline-start: 1em;
	margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

input,
input:focus,
textarea,
textarea:focus {
	font-size: inherit;
	border-style: none;
	outline: none;
	box-shadow: none;
	@include appearance(none);
}

strong {
	font-weight: bold;
}

@media (orientation: portrait) {
  body {
    font-size: calc(18 * ((100vw / 677)));
  }
}