@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
.PopupVenue {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(black, 0.8);
	z-index: 10;
	@include flex('center', 'center');
	.PopupVenue-closeLayer {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.PopupVenue-content {
		position: relative;
		width: 51em;
		height: 30em;
		overflow: auto;
		background-color: white;
		border-radius: 0.4em;
		
		color: black;
		text-align: left;

		.PopupVenue-header {
			position: relative;
			padding: 1em 2em 0 2em;
			.PopupVenue-title {
				position: relative;
				font-weight: bold;
				margin-bottom: 1em;
				span {
					font-size: 2em;
				}
			}
			.PopupVenue-closeBtn {
				position: absolute;
				top: 0em;
				right: 0em;
				width: 2.5em;
				height: 2.5em;
				background-image: url('../../assets/images/icon-close.svg');
				background-size: auto 1em;
				background-position: center center;
				&:hover {
					background-size: auto 1.2em;
				}
			}
		}
		.PopupVenue-body {
			position: relative;
			padding: 0em 2em 1em 2em;
			.PopupVenue-description {
				position: relative;
				.PopupVenue-descriptionText {
					p {
						margin: 0;
						line-height: 1.3;
					}
				}
			}

		

			.PopupVenue-locations {
				margin-top: 2em;
				.PopupVenue-location {
					margin-bottom: 2em;
					.PopupVenue-locationTitle {
						span {
							font-size: 1.25em;
							font-weight: bold;	
							&.red {
								color: red;
							}
						}
						
					}
					.PopupVenue-locationAddress {
						position: relative;
						padding-left: 1.5em;
						background-image: url('../../assets/images/icon-location2.svg');
						background-size: auto 1em;
						background-position: left center;
						
					}
					.PopupVenue-locationWebsite {
						
							position: relative;
							padding-left: 1.5em;
							background-image: url('../../assets/images/icon-website.svg');
							background-size: auto 1em;
							background-position: left center;
							margin-bottom: 0.5em;
							a {
								text-decoration: none;
								&:hover {
									text-decoration: underline;
								}
							}
						
					}
					
				}
			}
		}
	}
	
	&.portrait {
		padding-top: 2em;
		@include flex('center', 'flex-start');
		.PopupVenue-content {
			width: calc(100% - 4em);
			height: auto;
			max-height: calc(100% - 10em);
			.PopupVenue-body { 
				padding: 0em 1.3em 1em 1.3em;
			}
		}
	}
}
