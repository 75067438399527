@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
.Rooms {
	position: relative;
	width: 100%;
	min-height: 100%;
	overflow: auto;
	color: white;
	text-align: left;
	padding: 1em 0 0 1em;



	.Rooms-room {
		position: relative;
		display: inline-block;
		width: 15em;
		height: 13em;
		margin: 0 1em 1em 0;
		padding-top: 10em;
		color: white;
		background-color: black;
		line-height: 1em;
		text-align: left;
		border-radius: 0.4em;
		background-color: black;
		background-size: cover;		
		background-position: top center;
		background-repeat: no-repeat;
		overflow: hidden;
		
		&:nth-child(4n) {
			margin-right: 0;
		}
		&:hover {
			.Rooms-roomInfo {
				top: 0em;
			}		
		}

		.Rooms-roomStatus {
			position: absolute;
			top: 1em;
			right: 1em;
			padding: 0.25em 0.5em;
			background-color: white;
			span {
				font-size: 1.5em;
				color: black;
				font-weight: bold;
			}
			&.closed {
				span {
					color: red;
				}
			}
		}

		.Rooms-roomInfo {
			position: absolute;
			top: 10em;
			left: 0;
			width: 100%;
			height: 13em;
			padding: 0.5em;
			background-color: rgba(0, 0, 0, 0.8);
			transition: top 0.5s ease-in-out;
			-webkit-transition: top 0.25s ease-in-out;
			overflow: hidden;

			

			.Rooms-roomName {
				font-weight: bold;
				padding-bottom: 0.25em;
				white-space: nowrap;	
			}
			
			.Rooms-venueName,
			.Rooms-numberOfRooms {
				color: gray;
				font-size: 0.85em;
				line-height: 1em;
				white-space: nowrap;	
			}
			.Rooms-venueName {
				&:hover  {
					text-decoration: underline;
				}
			}
			
			.Rooms-extraInfo {
				padding-top: 0.5em;
				&--noRating {
					padding-top: 2.5em;
				}
				> div {
					margin-bottom: 0.6em;
					font-size: 0.875em;
					> span {
						display: inline-block;
						width: 7em;
					}
				}
				.Rooms-website {
					overflow: hidden;
					white-space: nowrap;

					a {
						cursor: pointer;
						text-decoration: none;
						font-size: 0.85em;
						&:hover {
							text-decoration: underline;
						}
					}

				}
			}
		}
		.Rooms-completedStatus {
			position: absolute;
			top: 0.25em;
			right: 0.25em;
			width: 1.5em;
			height: 1.5em;
			background-color: white;
			border-radius: 100%;
			cursor: pointer;
			&.completed {
				background-image: url('../../assets/images/icon-checkmark.svg');
				background-size: contain;
			}
			&:hover {
				background-color: darken(white, 5%);
				cursor: pointer;
			}
		}
	}	

	&.venue-popup {
		padding: 0;
		.Rooms-room {
			margin: 0 1em 1em 0;
			&:nth-child(3n) {
				margin-right: 0;
			}
		}
	}
	&.portrait {
		.Rooms-room {
			margin: 0 1em 1em 0;
			&:nth-child(2n) {
				margin-right: 0;
			}
		}
	}


	&.portrait {
		&.venue-popup {
			.Rooms-room {
				margin: 0 1em 1em 0;
				&:nth-child(2n) {
					margin-right: 0;
				}
			}
		}	
	}
}