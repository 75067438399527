@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
.LanguageIcons {
	position: relative;
	display: inline-block;
}

.LanguageIcons-icon {
	display: inline-block;
	position: relative;
	vertical-align: center;
	width: 1em;
	height: 1em;
	margin: 0 0.25em;
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
	opacity: 0.5;
	filter: grayscale(1);
	-webkit-filter: grayscale(1);
	.big {
		width: 1.25em;
		height: 1.25em;
	}
	&.da {background-image: url('../../../assets/images/icon-da.svg');}
	&.en {background-image: url('../../../assets/images/icon-en.svg');}
	&.de {background-image: url('../../../assets/images/icon-de.svg');}
	&.included {
		opacity: 1;
		filter: grayscale(0);
		-webkit-filter: grayscale(0);
	}
}