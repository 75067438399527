@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
.SortingPanel {
	position: relative;
	.SortingPanel-title {
		width: 11em;
		color: white;
		display: inline-block;
		vertical-align: middle;
		font-weight: bold;
	}

	.SortingPanel-container {
		display: inline-block;
		vertical-align: middle;
		.SortingPanel-direction {
			display: inline-block;
			vertical-align: middle;
			height: 2em;
			width: 0.9em;
			margin-right: 0.75em;
			background-size: 100% auto;
			background-position: left center;
			background-repeat: no-repeat;
			cursor: pointer;
			&.ASC {
				background-image: url('../../../assets/images/arrows-up.svg');
			}
			&.DESC {
				background-image: url('../../../assets/images/arrows-down.svg');
			}
		}
		.SortingPanel-dropdown {
			display: inline-block;
			vertical-align: middle;
			width: 10em;
		}
	}

}

