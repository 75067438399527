@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
.RoomsOrVenues {
	position: relative;
	width: 100%;
	min-height: 100%;
	overflow: auto;
	color: white;
	text-align: left;
	padding: 0em 3em;

	.RoomsOrVenues-filters {
		position: relative;
		z-index: 1;
	}

	.RoomsOrVenues-about {
		z-index: 2;
		position: absolute;
		height: 2em;
		width: 2em;
		top: 2em;
		right: 3em;
		background-image: url('../../assets/images/icon-about.svg');
		background-size: 90% auto;
		cursor: pointer;
		@include opacity(0.5);
		&:hover {
			background-size: 100% auto;
			@include opacity(0.8);
		}
	}

	.RoomsOrVenues-map {
		z-index: 2;
		position: absolute;
		display: block;
		top: 5em;
		right: 3em;
		width: 10em;
		height: 10em;
		padding: 0.25em;
		text-align: center;
		background-image: url('../../assets/images/regions-white.svg');
		background-size: auto 7.5em;
		background-position: bottom 0.25em center;
		border: 0.1em solid white;
		border-radius: 0.4em;
		cursor: pointer;
	}


	.RoomsOrVenues-pagesNav {
		position: relative;
		@include flex('flex-start', 'flex-end');
		.RoomsOrVenues-pagesNavItem {
			background-color: rgba(white, 0.5);
			width: 16em;
			height: 2em;
			border-top-left-radius: 0.4em;
			border-top-right-radius: 0.4em;
			padding: 0 1em;
			@include flex('flex-start', 'center');
			cursor: pointer;
			&.rooms {
				margin-left: 1em;
				width: 15em;
			}
			&.selected,
			&:hover {
				background-color: white;
			}
			span {
				font-weight: bold;
				color: black;
			}
		}
	}
	.RoomsOrVenues-pages {
		position: relative;
		background-color: white;
		border-top-right-radius: 0.4em;
		border-bottom-right-radius: 0.4em;
		border-bottom-left-radius: 0.4em;
		margin-bottom: 1em;
	}

	&.portrait {
		padding: 0em 2.3em;
		.RoomsOrVenues-about {
			top: 4.5em;
			right: 1em;
			width: 1.8em;
			height: 1.8em;
		}
		.RoomsOrVenues-map {
			top: 7em;
			right: 1em;
			width: 5em;
			height: 5em;
			padding: 0;
			background-size: auto 3.5em;
			background-position: bottom 0.1em center;
		}
	}
}