@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
.LocationPanel {
	position: relative;
	.LocationPanel-title {
		width: 11em;
		color: white;
		display: inline-block;
		vertical-align: middle;
		font-weight: bold;
	}

	.LocationPanel-dropdown {
		display: inline-block;
		vertical-align: middle;
		width: 10em;
		&.region {
			margin-right: 1em;
		}
	}
}



