@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
.Header {
	position: relative;
	width: 100%;
	height: 4em;
	background-color: #0b0c10;
	.Header-title {
		position: absolute;
		left: 0;
		color: white;
		padding-top: 1.25em;
		padding-left: 2em;
		font-weight: bold;
		cursor: pointer;
		
		span {
			display: inline-block;
			text-transform: uppercase;
			font-size: 1.75em;
			line-height: 1;
			margin-right: 1em;
		}
	}

	.Header-languages {
		position: absolute;
		right: 2em;
		top: 0;
		.Header-language {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			line-height: 2.8571;
			padding-left: 2em;
			cursor: pointer;
			span {
				font-size: 0.7em;	
				color: grey;
			}
			&::before {
				content: '';
				opacity: 0.5;
				position: absolute;
				left: 0;
				top: 0;
				height: 100%;
				width: 100%;
				background-size: auto 1.5em;
				background-position: left center;
				background-repeat: no-repeat;
			}
			&.da {
				margin-right: 1em;
				&::before {background-image: url('../../../assets/images/icon-da2.svg');}
			}
			&.en {
				&::before {background-image: url('../../../assets/images/icon-en2.svg');}
			}
			&.selected {
				color: white;
				&::before {opacity: 1;}
			}
		}
	}

	.Header-logout {
		position: absolute;
		right: 1em;
		top: 2.2em;
		width: 1.5em;
		height: 1.5em;
		background-image: url('../../../assets/images/icon-logout.svg');
		cursor: pointer;
		@include opacity(0.5);
		&:hover {
			@include opacity(1);
		}
	}

	&.portrait {
		.Header-languages { 
			right: 0.5em;
			top: 0.25em;
			.Header-language {
				display: block;
				width: 2.5em;
				height: 1.8em;
				padding: 0;
				margin: 0;
				line-height: 1;
				span {
					display: none;
				}
				&::before {
					background-size: auto 2em;
					background-position: left top;
				}
			}
		}
		.Header-logout {
			top: 0.5em;
			right: 4em;
		}

	}
}




