@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
.Select {
	position: relative;
	width: 100%;
	height: 1.5em;
	margin: 0.25em 0;
	border-radius: 0.4em;
	color: black;
	background-color: white;
	text-align: left;
	cursor: pointer;
	&.open {
		border-radius: 0.4em 0.4em 0 0;
		.Select-dropdown {
			height: auto;
			max-height: 10em;
			overflow: auto;
			border-radius: 0 0 0.4em 0.4em;
			border-top: 1px solid #213640;
		}
	}

	
	.Select-panel {
		height: 1.5em;
		padding: 0 0.5em;
	}
	
	.Select-selected {
		span {
			font-size: 1em;
			line-height: 1.5em;
		}
	}
	.Select-button {
		position: absolute;
		top: 0;
		right: 0;
		width: 1.5em;
		height: 1.5em;
		background-image: url('../../../assets/images/icon-arrow-down.svg');
		background-position: top 0.5em right 0.4em;
		background-size: auto 0.5em;
		background-repeat: no-repeat;
	}

	.Select-dropdown {
		position: absolute;
		left: 0;
		top: 1.5em;
		width: 100%;
		height: 0;
		background-color: white;
		overflow: hidden;
		z-index: 2;
		// @include transition('height', 0.3s, 'ease');
		
		.Select-option {
			cursor: pointer;
			position: relative;			
			padding: 0 0.5em;
			height: 1.5em;
			span {
				font-size: 1em;
				line-height: 1.5;
			}
			&:hover {
				background-color: rgba(#213640, 0.1);
			}
			&--disabled {
				cursor: not-allowed;
				opacity: 0.5;
				&:hover {
					background-color: white;
				}
			}
			&.selected {
				background-color: rgba(#213640, 0.1);
			}
			&.denmark, 
			&.all-cities{
				border-bottom: 1px dashed black;
			}
		}
	}
}