@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
.PopupLogin {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(black, 0.8);
	z-index: 10;
	@include flex('center', 'center');
	.PopupLogin-closeLayer {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.PopupLogin-content {
		position: relative;
		margin: auto;
		width: 20em;
		border-radius: 0.4em;
		padding: 1em 2em;
		background-color: white;

		
		.PopupLogin-title {
			position: relative;
			margin-bottom: 1em;
			span {
				font-weight: bold;
				font-size: 1.5em;
			}
		}

		.PopupLogin-closeBtn {
			position: absolute;
			top: 0em;
			right: 0em;
			width: 2.5em;
			height: 2.5em;
			background-image: url('../../assets/images/icon-close.svg');
			background-size: auto 1em;
			background-position: center center;
			&:hover {
				background-size: auto 1.2em;
			}
		}

		.PopupLogin-form {
			position: relative;
			input {
				display: block;
				font-size: 1.11em;
				line-height: 1;
				width: 100%;
				height: 2.5em;
				border-radius: 0.4em;
				margin: auto;
				margin-bottom: 1em;
				padding: 0 1em;
				color: #275150;
				font-weight: normal;
				text-align: left;
				@include box-shadow-inset(0, 0.17em, 0.5em, 0, rgba(#C6C6C6, 1));
				&::placeholder {
					color: rgba(#275150, 0.25);
				}
			}
			.PopupLogin-loginBtn {
				background-color: black;
				height: 2.5em;
				width: 100%;
				border-radius: 0.4em;
				cursor: pointer;
				@include flex('center', 'center');
				span {
					color: white;
					font-weight: bold;
				}
				&.loading {
					background-image: url('../../assets/images/icon-loading-white.svg');
					background-size: auto 2em;
					background-position: center right 1em;
				}
			}
			.PopupLogin-errorMessage {
				height: 1.5em;
				margin-top: 1em;
				margin-bottom: 0.6em;
				padding: 0 1em;
				text-align: center;
				span {
					color: red;
					font-size: 0.89em;
				}
			}
		}
	}

	&.portrait {
		padding-top: 2em;
		@include flex('center', 'flex-start');
		
		.PopupLogin-content {
			padding: 2em;
			width: calc(100% - 4em);
			margin: 0;
			height: 42em;
			padding: 2.3em 1.3em;
		}
	}
}