@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
.PopupMap {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(black, 0.8);
	z-index: 10;
	@include flex('center', 'center');
	.PopupMap-closeLayer {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.PopupMap-content {
		position: relative;
		margin: auto;
		width: 43em;
		height: 39em;
		padding: 2em 4em;
		background-color: white;

		.PopupMap-closeBtn {
			position: absolute;
			top: 0em;
			right: 0em;
			width: 2.5em;
			height: 2.5em;
			background-image: url('../../assets/images/icon-close.svg');
			background-size: auto 1em;
			background-position: center center;
			&:hover {
				background-size: auto 1.2em;
			}
		}
	}

	&.portrait {
		padding-top: 2em;
		@include flex('center', 'flex-start');
		
		.PopupMap-content {
			padding: 2em;
			width: calc(100% - 4em);
			margin: 0;
			height: 42em;
			padding: 2.3em 1.3em;
		}
	}
}